<template>
  <div class="list row">
    <h2>Poreske Stope</h2>
    <div class="actions d-flex flex-row-reverse">
      <button
        type="button"
        class="btn btn-primary bi bi-arrow-clockwise"
        @click="refreshData"
      />
      <button
        type="button"
        class="btn btn-success bi bi-plus"
        @click="addData"
      />
    </div>
    <data-table
      url="tax-rates"
      :columns="columns"
      :templates="templates"
      :headings="headers"
      :actions="actions"
      ref="datatable"
      @yes="deleteData"
      name="TaxRates"
    >
    </data-table>
  </div>
</template>

<script>
import TaxRatesDataService from "../../services/tax-rates.service";
import DataTable from "../widgets/DataTable.vue";
import TaxRatesForm from "./TaxRatesForm.vue";

export default {
  name: "tax-rates",
  components: {
    DataTable,
  },
  data() {
    return {
      columns: ["name", "label", "rate", "edit"],
      headers: {
        name: "Naziv",
        label: "Label",
        rate: "Stopa (%)",
        edit: "",
      },
      templates: {},
      actions: {
        edit: { commp: TaxRatesForm },
        delete: {
          deleteData: this.deleteData,
          color: "danger",
          icon: "bi-trash",
        },
        // print: null,
      },
      currentTutorial: null,
      currentIndex: -1,
      title: "",
    };
  },
  methods: {
    refreshData() {
      this.$refs.datatable.onRefresh();
    },
    addData() {
      this.$store.commit("app/showModal", {
        modal: TaxRatesForm,
        params: { data: {}, title: "Dodaj novu Stopu" },
      });
    },
    deleteData(id) {
      TaxRatesDataService.delete(id)
        .then((response) => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: response.data.message,
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>

<style scoped>
.list {
  text-align: left;
  /* max-width: 750px; */
  margin: auto;
}
</style>
