<template>
  <data-form
    :data="data"
    :formFields="formFields"
    @save="saveData"
    @cancel="cancel"
  />
</template>

<script>
import DataForm from "../widgets/DataForm.vue";
import TaxRatesDataService from "../../services/tax-rates.service";

export default {
  name: "tutorial-form",
  props: ["data"],
  components: {
    DataForm,
  },
  data() {
    return { message: "" };
  },
  computed: {
    formFields() {
      return {
        id: { type: "hidden" },
        name: { label: "Naziv", type: "text" },
        label: { label: "Label", type: "text" },
        rate: { label: "Stopa (%)", type: "number" },
      };
    },
  },
  methods: {
    saveData(newData) {
      if (newData && newData.id) {
        this.updateTaxs(newData);
      } else {
        this.createTaxs(newData);
      }
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    updateTaxs(newData) {
      TaxRatesDataService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Stope su promenjene!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    createTaxs(newData) {
      TaxRatesDataService.create(newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Stope su kreirane!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>